import services, { servicesNew } from "../../util/services";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import logo from "./assets/Logo.png";
import clinic from "./assets/data/clinic.svg";
import mail from "./assets/actions/mail-sharp.svg";
import calendar from "./assets/actions/calendar-clear-sharp.svg";
import vet from "./assets/data/vet.svg";
import pet from "./assets/data/pet.svg";
import owner from "./assets/data/pet-owner.svg";
import checkbox from "./assets/forms/checkbox.svg";
import { useParams } from "react-router-dom";
import checkbox_empty from "./assets/forms/checkbox-empty.svg";
import content from "../../util/content/content";
import styles from "./RequisitionFormSummaryPDF.module.css";

export const RequisitionFormSummaryPDF = (props) => {
	const [data, setData] = useState({});
	const [end, setEnd] = useState(false);
	const [dateCalculation, setDateCalculation] = useState();
	const { id } = useParams();

	useEffect(() => {
		(async () => {
			try {
				let form = await servicesNew.get(
					`/requisitionForm/${id}?content=organization`
				);
				setData(form);

				const date = new Date(form.birthday);
				const today = moment(new Date());
				const years = today.diff(date, "years");
				const months = today.diff(date, "months") % 12;
				setDateCalculation({ years, months });
			} finally {
				setTimeout(() => setEnd(true), 200);
			}
		})();
	}, []);

	return (
		<div className={`${styles.container} ${styles.body}`} style={{ height: "800px" }}>
			<header>
				<div className={`${styles.row} ${styles.alignItemsCenter}`}>
					<div className={`${styles.col3}`}>
						<img
							src={logo}
							alt="logo Basepaws Vets"
							className={`${styles.logo}`}
						/>
					</div>
					<div className={`${styles.col9}`}>
						<h2 className={`${styles.h2}`}>Requisition Form Summary</h2>
						<h1 className={`${styles.h1}`}>ID {data.handle}</h1>
					</div>
				</div>
				<hr />
			</header>

			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>URL</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>
						<a href={"https://my.basepaws.com" + data.url}>
							{"https://my.basepaws.com" + data.url}
						</a>
					</p>
				</div>
			</div>
			{data?.organization && (
				<div className={`${styles.row} ${styles.mb10}`}>
					<div className={`${styles.col3}`}>
						<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Clinic</h2>
					</div>
					<div className={`${styles.col9}`}>
						<p className={`${styles.mb10}`}>
							<img src={clinic} className={`${styles.mr10}`} />
							{data?.organization?.company}
						</p>
						<p>
							<img src={mail} className={`${styles.mr10}`} />
							{data?.organization?.email}
						</p>
					</div>
				</div>
			)}
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>
						Barcode ID on Collection Tube
					</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>{data?.kit?.code}</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Test based on ID</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>{content.report_card[data?.kit?.productName]?.name}</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Collection Date</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>
						<img src={calendar} className={`${styles.mr10}`} />
						<Moment format="MM/DD/YYYY">{data?.date}</Moment>
					</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Annotations</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p className={`${styles.pr50}`}>
						{data.annotation ? data.annotation : "No Annotations"}
					</p>
				</div>
			</div>
			{data?.vet && (
				<div className={`${styles.row} ${styles.mb10}`}>
					<div className={`${styles.col3}`}>
						<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Veterinarian</h2>
					</div>
					<div className={`${styles.col9}`}>
						<p className={`${styles.mb10}`}>
							<img src={vet} className={`${styles.mr10}`} />
							{data?.vet?.first_name} {data?.vet?.last_name}
						</p>
						<p>
							<img src={mail} className={`${styles.mr10}`} />
							{data?.vet?.email}
						</p>
					</div>
				</div>
			)}
			{data?.pet && (
				<div className={`${styles.row} ${styles.mb10}`}>
					<div className={`${styles.col3}`}>
						<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Patient</h2>
					</div>
					<div className={`${styles.col9}`}>
						<p className={`${styles.mb10}`}>
							<img src={pet} className={`${styles.mr10}`} />
							{data?.pet?.name}
						</p>
						<p className={`${styles.mb10}`}>
							<img src={owner} className={`${styles.mr10}`} />
							{data?.pet?.owner?.first_name}
						</p>
						<p className={`${styles.mb10}`}>
							<img src={owner} className={`${styles.mr10}`} />
							{data?.pet?.owner?.last_name}
						</p>
						<p>
							<img src={mail} className={`${styles.mr10}`} />
							{data?.pet?.owner?.email}
						</p>
					</div>
				</div>
			)}
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Birth Date</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>
						<img src={calendar} className={`${styles.mr10}`} />
						<Moment format="MM/DD/YYYY">{data?.birthday}</Moment>
					</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Age</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>
						{dateCalculation?.years == 0
							? ""
							: `${dateCalculation?.years}y`}{" "}
						{dateCalculation?.months == 0
							? ""
							: `${dateCalculation?.months}m`}
					</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Specie</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>{data?.species == "cat" ? "Feline" : "Canine"}</p>
				</div>
			</div>
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Gender</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>{data?.gender == "male" ? "Male" : "Female"}</p>
				</div>
			</div>
			{data?.spayed && <div className={`${styles.row} ${styles.mb20}`}>
				<div className={`${styles.col3}`}>
					<h2 className={`${styles.h2} ${styles.textRight} ${styles.pr10}`}>Spayed/Neutered</h2>
				</div>
				<div className={`${styles.col9}`}>
					<p>{data?.spayed == "no" ? "No" : "Yes"}</p>
				</div>
			</div>}
			<div className={`${styles.row} ${styles.mb10}`}>
				<div className={`${styles.col3} ${styles.textRight}`}>
					{data?.accept_research ? (
						<img src={checkbox} className={`${styles.pr10}`} />
					) : (
						<img src={checkbox_empty} className={`${styles.pr10}`} />
					)}
				</div>
				<div className={`${styles.col9}`}>
					<p className={`${styles.pr50}`}>
						I accept to participate in <b>Research</b> since prior
						medical history in recognizing genetic patterns and
						improving our research.
					</p>
				</div>
			</div>

			<footer>
				<hr />
				<div className={`${styles.row}`}>
					<div className={`${styles.col6}`}>
						<p>
							Email us if you have any questions at{" "}
							<b>vet@basepaws.com</b>
						</p>
					</div>
					<div className={`${styles.col6}`}>
						<p className={`${styles.textRight}`}>
							© 2022. Basepaws, Inc. All rights reserved.
						</p>
					</div>
				</div>
			</footer>
			{end && <span id="end" />}
		</div>
	);
};
