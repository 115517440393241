import React from "react";
import { Footer } from "../components/footer/Footer.tsx";
import { Header } from "../components/header/Header.tsx";
import styles from './VetReportPDF.module.css'

export const Page = (props) => {
	return (
		<div className={styles.page} ref={props.top}>
			<div style={{ minHeight: "calc(100vh - 200px)" }}>
				<Header
					data={props.data}
					headerTitle={props.headerTitle}
					name={props?.pill?.color}
					description={props?.pill?.title}
					diseaseName={props.name}
				/>
				{props.children}
			</div>
			<Footer />
		</div>
	);
};
