import { updateObject } from '../../util/updateObject'

const initialState = []

const setPatients = (state = initialState, action) => {
  return updateObject(state, {
    patients: action.patients
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setPatients': return setPatients(state, action)
    default: return state
  }
}

export default reducer
