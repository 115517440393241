import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import { RequisitionFormSummaryPDF } from "./pages/requisitionForm/RequisitionFormSummaryPDF";
import { GeneralPage } from "./pages/canine/pages/GeneralPage.jsx";
import { Oral } from "./pages/feline/Oral";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/pdf/vet_report/:id" element={<GeneralPage />}></Route>
			<Route path="/pdf/felineOral/:id" element={<Oral />}></Route>
			<Route
				path="/pdf/requisition/:id"
				element={<RequisitionFormSummaryPDF />}
			></Route>
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
