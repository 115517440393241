import React, { useState, useEffect, useRef, ReactNode } from "react";
import styles from "./VetReportPDF.module.css";
import { Page } from "./Page.tsx";
//create an array of children that will go into page
//calculate the number of pages needed if page is too big. pagesize / 840 = number of pages
//split the array of children into n chunks(array). n = number of pages
//for each chunk, create a page
//840 px = the largest acceptable size the pageRef div can be before overflow to next page

export const Binder = (props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [chunks, setChunks] = useState<ReactNode[][]>([]);

  useEffect(() => {
    const pages = contentRef.current?.offsetHeight
      ? contentRef.current.offsetHeight / (props.breeds ? 600 : 725)
      : 0;
			// difficult to calculate number of pixels per inch within
			// print file as this can vary, but 725 is creating small enough
			// chunks for each page for the time being
    if (pages > 1) {
      const elements = React.Children.toArray(props.children).flat();
      const chunkSize = Math.ceil(elements.length / (Math.round(pages) + 1));
      // adding 1 to help distribute the elements more evenly across an extra page
      const chunks = Array(Math.ceil(elements.length / chunkSize))
        .fill(0)
        .map((_, index) =>
          elements.slice(index * chunkSize, (index + 1) * chunkSize)
        );
      setChunks(chunks);
    }
  }, [props.children]);

  const getPages = () => {
    if (chunks.length) {
      return chunks.map((chunk, index) => (
        <Page
          key={index}
          page={"true"}
          data={props.headerData}
          pill={props.data.headerPill}
          name={props.data.name}
        >
          <div className={`${styles.container}`}>
            {chunk}
          </div>
        </Page>
      ));
    } else {
      return (
        <Page
          page={"true"}
          data={props.headerData}
          pill={props.data.headerPill}
          name={props.data.name}
          headerTitle={props.headerTitle}
        >
          <div ref={contentRef} className={`${styles.container}`}>
            {props.children}
          </div>
        </Page>
      );
    }
  };

  return <>{getPages()}</>;
};
