import services, { servicesNew } from "../../../util/services";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from "./VetReportPDF.module.css";
import { Page } from "./Page.tsx";

/*
if markers_page length == 0 
display the all clear green banner 


else
show risk, high risk, carrier
page_list
list of carrier

clear - clear banner over list 
carrier - carrier banner over the list 

*/

export const SummaryRiskCarrier = ({ data }) => {
	const filterBreeds = (color) => {
		return data.markers.markers_page.filter(
			(el) => el.headerPill.color === color
		);
	};
	return (
		<>
			{data && (
					<Page data={data}>
						<div className={`${styles.container} ${styles.diseaseList}`}>
							<div className={`${styles.mb40}`}>
								<div className={`${styles.label} ${styles.highRisk} ${styles.mb10}`}>
									<h2 className={`${styles.headingBold}`}>
										{data.report_content.riskDefinition.highRisk.title}
									</h2>
								</div>
								<ul className={`${styles.mb20}`}>
									{filterBreeds("high-risk").length > 0 ? (
										<li className={`${styles.bodyRegular}`}>
											{filterBreeds("high-risk").map(
												(el) => el.name
											).join(', ')}
										</li>
									) : data.report_content.riskDefinition.highRisk.clear}
								</ul>
								<h4 className={`${styles.leadBold}`}>
									{data.report_content.riskDefinition.highRisk.subtitle}
								</h4>
								<p className={`${styles.legalRegular}`}>
									{data.report_content.riskDefinition.highRisk.description}
								</p>
							</div>
							<div className={`${styles.mb40}`}>
								<div className={`${styles.label} ${styles.risk}`}>
									<h2 className={`${styles.headingBold}`}>
										{data.report_content.riskDefinition.atRisk.title}
									</h2>
								</div>
								<ul className={`${styles.mb20}`}>
									{filterBreeds("risk").length > 0 ? (
										<li className={`${styles.bodyRegular}`}>
											{filterBreeds("risk").map(
												(el) => el.name
											).join(', ')}
										</li>
									) : data.report_content.riskDefinition.atRisk.clear}
								</ul>
								<h4 className={`${styles.leadBold}`}>
									{data.report_content.riskDefinition.atRisk.subtitle}
								</h4>
								<p
									className={`${styles.legalRegular}`}
									dangerouslySetInnerHTML={{
										__html: data.report_content.riskDefinition.atRisk
											.description,
									}}
								></p>
							</div>
							<div className={`${styles.mb40}`}>
								<div className={`${styles.label} ${styles.carrier}`}>
									<h2 className={`${styles.headingBold}`}>
										{data.report_content.riskDefinition.carrier.title}
									</h2>
								</div>
								<ul className={`${styles.mb20}`}>
									{filterBreeds("carrier").length > 0 ? (
										<li className={`${styles.bodyRegular}`}>
											{filterBreeds("carrier").map(
												(el) => el.name
											).join(', ')}
										</li>
									) : data.report_content.riskDefinition.carrier.clear}
								</ul>
								<h4 className={`${styles.leadBold}`}>
									{data.report_content.riskDefinition.carrier.subtitle}
								</h4>
								<p
									className={`${styles.legalRegular}`}
									dangerouslySetInnerHTML={{
										__html: data.report_content.riskDefinition.carrier
											.description,
									}}
								></p>
							</div>
						</div>
					</Page>
			)}
		</>
	);
};
