import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import user from './reducers/user'
import patients from './reducers/patients'

const rootReducer = combineReducers({
  userInReducer: user,
  patients: patients
})

export default function configureStore () {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(rootReducer,
    undefined,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  )
  return store
}
