import React, { useState, useEffect, useRef, createRef } from "react";
import styles from '../../pages/VetReportPDF.module.css'

export const GeneSummaryText = (props) => {
	const getContent = () => {
		return (
			<div>
				{Object.keys(props.data.markerDescriptions).map(
					(title, index) => (
						
						<div key={index} className={`${styles.mt20}`}>
							<h4 className={`${styles.bodyBold}`}>
								{props.data.markerDescriptions[title].title}
							</h4>
							<p
								className={`${styles.bodySmallRegular}`}
								dangerouslySetInnerHTML={{
									__html: props.data.markerDescriptions[
										title
									].content,
								}}
							></p>
						</div>
					
					)
				)}
			</div>
		);
	};
	return (
		<>
			{getContent()}
			{
				<div className={`${styles.mt20}`}>
					<div className={`${styles.label} ${styles.grey}`}>
						<p className={`${styles.bodyRegular}`}>
							{props.headerData.report_content.evidenceFooter}
						</p>
					</div>
				</div>
			}
		</>
	);
};
