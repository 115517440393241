import React, { useState, useEffect } from "react";
import styles from "./VetReportPDF.module.css";
import { Header } from "../components/header/Header.tsx";
import { Binder } from "./Binder.tsx";
import tracker_20 from "../../canine/assets/tracker/tracker_20.svg";
import tracker_40 from "../../canine/assets/tracker/tracker_40.svg";
import tracker_60 from "../../canine/assets/tracker/tracker_60.svg";
import tracker_80 from "../../canine/assets/tracker/tracker_80.svg";
import tracker_100 from "../../canine/assets/tracker/tracker_100.svg";

let iconMap = {
	20: tracker_20,
	40: tracker_40,
	60: tracker_60,
	80: tracker_80,
	100: tracker_100,
};

export const ScientificEvidenceTracker = ({ data, headerData }) => {

	const getPercentages = () => {
		return Object.keys(data.report_content.evidenceTracker).map((tracker,index) => {
            if (iconMap[tracker]) {
                return <div key={index} className={`${styles.row} ${styles.mb40}`}>
                <div className={`${styles.col4}`}>
                    <img src={iconMap[tracker]} />
                </div>
                <div className={`${styles.col8}`}>
                    <p className={`${styles.bodyRegular}`} dangerouslySetInnerHTML={{__html: data.report_content.evidenceTracker[tracker]}}>
                    </p>
                </div>
            </div>
            }
        });
	};
	return (
		<>
			{data && (
				<>
					<Binder data={data} headerData={headerData} headerTitle={"Scientific Evidence Tracking System"}>
						<div className={`${styles.container}`}>
							<p className={`${styles.bodyRegular} ${styles.mb40}`}>
								{data.report_content.evidenceTracker.introduction}
							</p>
							{getPercentages()}
						</div>
					</Binder>
				</>
			)}
		</>
	);
};
