import React, { useState, useEffect } from "react";
import styles from "./VetReportPDF.module.css";
import { Page } from "./Page.tsx";

export const SummaryClear = ({ data }) => {
	return (
		<>
			{data && (
				<>
					<Page data={data}>
						<div className={`${styles.container}`}>
							<div className={`${styles.mb40}`}>
								<div className={`${styles.label} ${styles.clear} ${styles.mb10}`}>
									<h2 className={`${styles.headingBold}`}>
										{data.report_content.riskDefinition['clear'].title}
									</h2>
								</div>
                                <h4 className={`${styles.leadBold}`}>
                                    {data.report_content.riskDefinition['clear'].subtitle}
								</h4>
								<p className={`${styles.bodyRegular}`}>
                                    {data.report_content.riskDefinition['clear'].description}
								</p>
							</div>
						</div>
					</Page>
				</>
			)}
		</>
	);
};
