import services, { servicesNew } from "../../../../util/services";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from "../../pages/VetReportPDF.module.css";

export const HeaderTag = (props) => {
	return (
		<div className={`${styles.dFlex} ${styles.justifyContentBetween}`}>
			<h2 className={`${styles.bodyBigRegular}`} style={{fontWeight: 600, fontSize: "12px"}}>{props.diseaseName}</h2>
			{<div className={`${styles.tag}`}>{props.description}</div>}
		</div>
	);
};
