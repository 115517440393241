import React from 'react'

import { Binder } from './Binder.tsx'
import styles from './VetReportPDF.module.css'

export const RiskBreeds = ({ data }) => {
  const filteredBreeds = data.breed?.filter(
    (breed) => isNaN(breed.percentage) || breed.percentage > 0
  )
  const getBreeds = () => {
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.mt20}`}>
          <h3 className={`${styles.leadBold} ${styles.mb5}`}>
            {data.animal.name}’s Breed Profile
          </h3>
          <div className={`${styles.row} ${styles.breedPercentRow}`}>
            {filteredBreeds.map((breed, index) => (
              <div key={index} className={`${styles.col24}`}>
                <p className={`${styles.leadBold}`}>
                  {!isNaN(breed.percentage)
                    ? `${parseFloat(breed.percentage)}%`
                    : breed.percentage.charAt(0).toUpperCase() +
                      breed.percentage.slice(1)}
                </p>
                <p className={`${styles.bodyRegular}`}>{breed.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  const getBreedDescriptions = () => {
    return filteredBreeds.map((breed, index) => (
      <div key={index} className={`${styles.container}`}>
        <div className={`${styles.mt20}`}>
          <h4 className={`${styles.leadBold} ${styles.mb5}`}>{breed.name}</h4>
          <p className={`${styles.breedDescription}`}>{breed.description}</p>
        </div>
      </div>
    ))
  }

  const breedFooterContent = data?.report_content?.breedFooter?.content
  return (
    <>
      {data && (
        <>
          <Binder data={data} headerData={data} breeds>
            {getBreeds()}
            {getBreedDescriptions()}
            {breedFooterContent ? (
              <div className={`${styles.container}`}>
                <div className={`${styles.mt20}`}>
                  <h4 className={`${styles.leadBold} ${styles.mb5}`}>
                    {data.report_content.breedFooter.content}{' '}
                    <a href={data.report_content.breedFooter.linkUrl}>
                      {data.report_content.breedFooter.linkLabel}
                    </a>
                    .
                  </h4>
                </div>
              </div>
            ) : null}
          </Binder>
        </>
      )}
    </>
  )
}
