import React, { useState, useEffect } from "react";
import { MarkersBreeds } from "./MarkersBreeds.tsx";
import { MarkersClear } from "./MarkersClear.tsx";
import { PatientInformation } from "./PatientInformation.tsx";
import { RiskBreeds } from "./RiskBreeds.tsx";
import { ScientificEvidenceTracker } from "./ScientificEvidenceTracker.tsx";
import { SummaryClear } from "./SummaryClear.tsx";
import { SummaryRiskCarrier } from "./SummaryRiskCarrier.tsx";
import { servicesNew } from "../../../util/services";
import { useParams } from "react-router-dom";
import { Oral } from "../../feline/Oral";
import styles from './VetReportPDF.module.css'

// To use mocked data, we need to copy the static/vet.json inside /src. Then uncomment the following line, and uncomment a block inside the useEffect
// import jsonDateRaw from '../../../static/vet.json';

export const GeneralPage = () => {
	const [data, setData] = useState();
	const { id } = useParams();
	const [end, setEnd] = useState(false);

	useEffect(() => {
		async function fetchData() {
			let res = await servicesNew.get(`/vetPortal/reportData/${id}`);
			// const injectedContent = jsonDateRaw.content.cat.breedFooter;
			// res['report_content']['breedFooter'] = injectedContent;
			setData(res);
		}
		try {
			fetchData();
		} catch (error) {
			console.warn(error)
		} finally {
			setTimeout(() => setEnd(true), 500);
		}
	}, [id]);

	var health = data?.markers.markers_list.length>0;
	var breed = data?.breed!=null;
	return (
		<>
			{data && (
				<div className={styles.body}>
					{health&&breed?<PatientInformation data={data} />:<></>}
					{data.oral ? <Oral data={data} showClinic={!health && !breed} /> : <></>}
					{health?<>
						{data.markers.markers_page == 0 ? (
							<SummaryClear data={data} />
						) : (
							<SummaryRiskCarrier data={data} />
						)}
						{data.markers.markers_page.map((el, index) => {
							return (
								<MarkersBreeds
									key={index}
									index={index}
									headerData={data}
									data={el}
								></MarkersBreeds>
							);
						})}
						<MarkersClear headerData={data} data={data} />					
					</>:<></>}
					{breed?<RiskBreeds data={data} />:<></>}
					{health&&breed && data.markers.markers_page.length > 0 ?<ScientificEvidenceTracker headerData={data} data={data} />:<></>}
					{end && <span id="end" />}
					{/* this span is being used by lambda function that creates
					the pdf to know when the doc is fully loaded */}
				</div>
			)}
		</>
	);
};
