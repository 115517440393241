const content = {
    "report_card": {
        "type_breed": {
            "name": "Breed DNA Test",
            "icon": "basepaws-icon-report-type-health-markers.svg"
        },
        "type_dental": {
            "name": "Feline ORL-DD",
            "icon": "basepaws-icon-report-type-dental.svg"
        },
        "type_breed_dna": {
            "name": "Breed + Health DNA Test",
            "icon": "basepaws-icon-report-type-health-markers.svg"
        },
        "type_dna_complete": {
            "name": "Feline Baseline B&H + ORL-DD",
            "icon": "basepaws-icon-report-type-health-markers.svg"
        },
        "type_breed_dna_complete": {
            "name": "Feline Baseline B&H + ORL-DD",
            "icon": "basepaws-icon-report-type-health-markers.svg"
        },
        "type_wgs": {
            "icon": "basepaws-icon-report-type-whole.svg",
            "name": "Whole Genome Sequence"
        },
        "type_canine_breed_and_health": {
            "name": "Canine Baseline B&H",
            "icon": "basepaws-icon-report-type-whole.svg",
        }
    }}
  export default content
  