import services, { servicesNew } from "../../../util/services";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from "./VetReportPDF.module.css";
import { Header } from "../components/header/Header.tsx";
import { Footer } from "../components/footer/Footer.tsx";
import { Binder } from "./Binder.tsx";

export const MarkersClear = ({ data, headerData }) => {
	const getMarkers = () => {
		return data.markers.markers_list.map((el, index) => (
			<table key={index} className={`${styles.w100} ${styles.table} ${styles.mt20} ${styles.bigTable}`}>
				<thead>
				{index == 0 && (					
						<tr className={`${styles.bodyBold}`}>
							<th className={`${styles.w50} ${styles.textLeft}`}>Disease</th>
							<th>Gene</th>
							<th>Mutation</th>
							<th>Status</th>
						</tr>					
				)}
				</thead>
				<tbody>
					<tr className={`${styles.bodySmallRegular}`}>
						<td className={`${styles.w50} ${styles.textLeft}`}>{el.disease}</td>
						<td className={`${styles.mkTableCell}`}>{el.gene}</td>
						<td className={`${styles.mkTableCell}`}>{el.mutation}</td>
						<td className={`${styles.mkTableCell}`}>{el.status}</td>
					</tr>
				</tbody>
			</table>
		));
	};
	return (
		<>
			{data && (
				<>
					<Binder
						headerTitle={`${data.report_content.markersClearHeaderTitle} (${data.markers.markers_list.length})`}
						headerData={headerData}
						data={data}
					>
                        {<div className={`${styles.mb40}`}>
								<div className={`${styles.label} ${styles.clear} ${styles.mb10}`}>
									<h2 className={`${styles.headingBold}`}>
                                        {data.report_content.riskDefinition['clear'].title}
									</h2>
								</div>
                                <h4 className={`${styles.leadBold}`}>
                                {data.report_content.riskDefinition['clear'].subtitle}
								</h4>
								<p className={`${styles.bodyRegular}`}>
                                {data.report_content.riskDefinition['clear'].description}
								</p>
                               
							</div>
                        }
						{getMarkers()}
					</Binder>
				</>
			)}
		</>
	);
};
