import React from "react";
import styles from '../../pages/VetReportPDF.module.css'

export const Footer = () => {
	return (		
		<footer className={`${styles.footer}`}>
			<hr />
			<div className={`${styles.row}`}>
				<p className={`${styles.bodyRegular}`}>
					Basepaws veterinary support:{" "}
					<span className={`${styles.bodyBold}`}>vet@basepaws.com</span> or{" "}
					<span className={`${styles.bodyBold}`}>888-636-4691</span>
				</p>
			</div>
		</footer>		
	);
};
