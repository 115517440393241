import services, { servicesNew } from "../../util/services";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import logo from "../requisitionForm/assets/Logo.png";
import zero from "./chart-oral-risk/value=0.svg";
import one from "./chart-oral-risk/value=1.svg";
import two from "./chart-oral-risk/value=2.svg";
import three from "./chart-oral-risk/value=3.svg";
import four from "./chart-oral-risk/value=4.svg";
import five from "./chart-oral-risk/value=5.svg";
import six from "./chart-oral-risk/value=6.svg";
import seven from "./chart-oral-risk/value=7.svg";
import eight from "./chart-oral-risk/value=8.svg";
import nine from "./chart-oral-risk/value=9.svg";
import ten from "./chart-oral-risk/value=10.svg";
import styles from "./Oral.module.css";
import { Page } from "../canine/pages/Page.tsx";
//add logic for the oral risk score page
const getRisk = (num) => {
	if (num >= 0 && num <= 3.3) {
		return { class: styles.low, text: "Low" };
	}
	if (num > 3.3 && num < 6.6) {
		return { class: styles.medium, text: "Medium" };
	}
	if (num >= 6.6 && num <= 10) {
		return { class: styles.high, text: "high" };
	}
};
export const Oral = ({ data, showClinic = false }) => {
	let map = {
		0: zero,
		1: one,
		2: two,
		3: three,
		4: four,
		5: five,
		6: six,
		7: seven,
		8: eight,
		9: nine,
		10: ten,
	};
	return (
		<>
			{data && (
				<>
					<Page
						headerTitle={
							data.report_content.oralReport.header_title
						}
						data={data}
					>
						{showClinic && (
              <div className={`${styles.container}`}>
							<div>
								<h2 className={`${styles.headingRegular}`}>
									Clinic Information
								</h2>
								<hr />
								<div className={`${styles.mb50} ${styles.mt20}`}>
									<p className={`${styles.bodyBigBold} ${styles.mb5}`}>
										Clinic Name
									</p>
									<p className={`${styles.bodyBigRegular}`}>{data.clinic}</p>
								</div>
							</div>
						</div>
						)}
						<div className={`${styles.container} ${styles.body}`}>
							<p className={`${styles.leadRegular} ${styles.mb20} ${styles.mt20}`}>
								{data.report_content.oralReport.introduction}
							</p>

							<div>
								<h2 className={`${styles.headingRegular}`}>
									{data.report_content.oralReport.risk_title}
								</h2>
								<hr />
								<div className={`${styles.row} ${styles.mt30} ${styles.px60}`}>
									<div className={`${styles.col6} ${styles.textCenter} ${styles.px60}`}>
										<img
											src={map[data.oral.periodontalRisk]}
										/>
										<div>
											<span
												className={`${styles.tag} ${
													getRisk(
														data.oral
															.periodontalRisk
													).class
												}`}
											>
												{
													getRisk(
														data.oral
															.periodontalRisk
													).text
												}{" "}
												Risk
											</span>
										</div>
										<h3 className={`${styles.headingBold} ${styles.mb10} ${styles.mt20}`}>
											Periodontal Disease
										</h3>
										<p className={`${styles.bodyRegular}`}>
											{
												data.report_content.oralReport
													.periodontal_description
											}
										</p>
									</div>
									<div className={`${styles.col6} ${styles.textCenter} ${styles.px60}`}>
										<img
											src={map[data.oral.resorptionRisk]}
										/>
										<div>
											<span
												className={`${styles.tag} ${
													getRisk(
														data.oral.resorptionRisk
													).class
												}`}
											>
												{
													getRisk(
														data.oral.resorptionRisk
													).text
												}{" "}
												Risk
											</span>
										</div>
										<h3 className={`${styles.headingBold} ${styles.mb10} ${styles.mt20}`}>
											Tooth Resorption
										</h3>
										<p className={`${styles.bodyRegular}`}>
											{
												data.report_content.oralReport
													.resorption_description
											}
										</p>
									</div>
								</div>
							</div>

							<div className={`${styles.label} ${styles.grey} ${styles.textCenter} ${styles.mt30}`}>
								<p className={`${styles.bodyRegular}`}>
									{data.report_content.oralReport.disclaimer}
								</p>
							</div>

							<div className={`${styles.mt20}`}>
								<h4 className={`${styles.bodyBold}`}>
									{data.report_content.oralReport.how_title}
								</h4>
								<p className={`${styles.bodySmallRegular}`}>
									{
										data.report_content.oralReport
											.how_description
									}
								</p>
							</div>
						</div>
					</Page>
				</>
			)}
		</>
	);
};
