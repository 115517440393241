import React, { useState, useEffect, useRef } from "react";
import styles from "./VetReportPDF.module.css";
import { GeneSummaryText } from "../components/disease/GeneSummaryText.tsx";
import { Binder } from "./Binder.tsx";
import tracker_40 from "../../canine/assets/tracker/tracker_40.svg";

export const MarkersBreeds = (props) => {
	let topRef = useRef();
	useEffect(() => {
		//create an array of children that will go into page
		//calculate the number of pages needed if page is too big. pagesize / 840 = number of pages
		//split the array of children into n chunks(array). n = number of pages
		//for each chunk, create a page
		//660 px = the largest acceptable size the pageRef div can be before overflow to next page
		//console.log(topRef.current.offsetTop);
	}, []);
	const getCategories = () => {
		return props.data.geneList.map((el, index) => (
			<table key={index} className={`${styles.w100} ${styles.table} ${styles.fixed}`}>
				<thead>
				{index == 0 && (
					<tr className={`${styles.bodyBold}`}>
						<th>Gene</th>
						<th>Mutation</th>
						<th>Status</th>
						<th>Scientific evidence tracker *</th>
					</tr>
				)}
				</thead>
				<tbody>
					<tr className={`${styles.bodySmallRegular}`}>
						<td>{el.name}</td>
						<td>{el.mutation}</td>
						<td>{el.status}</td>
						<td>
							<img src={tracker_40} />
						</td>
					</tr>
				</tbody>
			</table>
		));
	};
	return (
		<>
			{props.data && (
				<>
						<Binder headerData={props.headerData} data={props.data}>
							{getCategories()}
							<GeneSummaryText
								index={props.index}
								data={props.data}
                                headerData={props.headerData}
								top={topRef}
							/>
						</Binder>
				</>
			)}
		</>
	);
};
